<template>
  <v-container v-if="!loading" id="add-User" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-account"
      :title="page_title()"
      class="px-5 py-3"
    >
      <v-tabs v-show="edit" v-model="tab" @change="reset_alert">
        <v-tab>{{ $t("basic") }}</v-tab>
        <v-tab v-if="userType === userTypeEnum.Investor">{{
          $t("files")
        }}</v-tab>
        <v-tab v-if="userType === userTypeEnum.Investor">{{
          $t("managed_portfolio")
        }}</v-tab>
        <v-tab
          v-if="userType === userTypeEnum.Partner"
          :disabled="!partnerB2bId"
          >{{ $tc("partner", 1) }}</v-tab
        >
        <v-tab v-if="userType === userTypeEnum.Investor">
          PREFERÊNCIAS DE CARTEIRA
        </v-tab>
        <v-tab v-if="userType === userTypeEnum.Investor">{{
          $t("bank_acc")
        }}</v-tab>
        <v-tab v-if="userType === userTypeEnum.Investor">{{
          $tc("background_check", 2)
        }}</v-tab>
        <v-tab v-if="userType === userTypeEnum.Banker">{{
          $tc("banker", 1)
        }}</v-tab>
        <v-tab v-if="userType === userTypeEnum.Investor">{{
          $tc("extract", 2)
        }}</v-tab>
      </v-tabs>
      <ManageUserBasic
        v-if="tab == tabsEnum.BASIC"
        @alert="show_alert"
        @saved="user_saved"
        :user_prop="user"
      ></ManageUserBasic>
      <ManageUserFiles
        v-if="edit && userType == userTypeEnum.Investor"
        v-show="tab == tabsEnum.FILES"
        @reset="update_files"
        :user_prop="user"
      ></ManageUserFiles>

      <UserPortfolioInfo
        v-if="edit && userType == userTypeEnum.Investor"
        v-show="tab == tabsEnum.MANAGED_PORTFOLIO"
        :User="userPortfolio"
      />
      <HighlightedCompanies
        @saved="handleSavedHighlightedCompanies"
        v-if="edit && userType == userTypeEnum.Investor"
        v-show="tab == tabsEnum.PREFERENCES"
        :User="user"
      ></HighlightedCompanies>
      <ManageUserPartner
        v-if="tab == tabsEnum.FILES && userType == userTypeEnum.Partner"
        @alert="show_alert"
        @saved="user_saved"
        :user_prop="partnerB2bInfo"
      ></ManageUserPartner>
      <ManageUserBanker
        v-if="tab == tabsEnum.FILES && userType == userTypeEnum.Banker"
        @alert="show_alert"
        @saved="user_saved"
        :user_prop="user"
      />
      <ManageBankAccounts
        v-if="edit && userType == userTypeEnum.Investor"
        v-show="tab == tabsEnum.BANK_ACCOUNTS"
        :User="user"
        :BankAccounts="user.BankAccounts"
      ></ManageBankAccounts>
      <ManageBackgroundCheck
        v-show="tab == tabsEnum.BACKGROUND_CHECK"
        v-if="edit && userType == userTypeEnum.Investor"
        :User="user"
        :BankAccounts="user.BankAccounts"
      ></ManageBackgroundCheck>
      <ExtractTableComponent
        v-show="tab == tabsEnum.EXTRACT"
        :userId="user_id"
        v-if="edit && userType == userTypeEnum.Investor"
      />
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import ManageUserBasic from "./tabs/Basic";
import ManageUserFiles from "./tabs/Files";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "AddUser",

  components: {
    ManageUserBasic,
    ManageUserFiles,
    ManageUserPartner: () => import("./tabs/Partner"),
    ManageUserBanker: () => import("./tabs/Banker"),
    HighlightedCompanies: () => import("./tabs/HighlightedCompanies"),
    ManageBankAccounts: () => import("./tabs/BankAccounts"),
    ManageBackgroundCheck: () => import("./tabs/BackgroundCheck"),
    ExtractTableComponent: () =>
      import("@/components/dxa/Extract/ExtractTableComponent"),
    UserPortfolioInfo: () =>
      import("@/views/pages/user/inviteds/UserInfo/tabs/UserPortfolio.vue"),
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    user_id: null,
    edit: false,
    success: null,
    alert_msg: null,
    user: null,
    userType: null,
    partnerB2bInfo: null,
    partnerB2bId: null,
    tab: 0,
    userTypeEnum: UserTypeEnum,
    tabsEnum: {
      BASIC: 0,
      FILES: 1,
      MANAGED_PORTFOLIO: 2,
      PREFERENCES: 3,
      BANK_ACCOUNTS: 4,
      BACKGROUND_CHECK: 5,
      EXTRACT: 6,
    },
    userPortfolio: {},
    userPortfolioLoading: false,
  }),

  computed: {},

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });

      if (this.tab === this.tabsEnum.MANAGED_PORTFOLIO) {
        this.getUserPortfolioInfo();
      }
    },
  },

  async created() {
    this.user_id = this.$route.params.user_id;
    this.loading = true;

    if (this.user_id != null && this.user_id != undefined) {
      await this.getUserInfo();
    } else {
    }
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    } else {
      this.$router.replace({ query: { tab: this.tab } });
    }
    this.loading = false;
  },

  methods: {
    async getUserPortfolioInfo() {
      this.loading = true;

      await this.apiService
        .postRequest(`investor/user-info`, { Id: this.user_id })
        .then((resp) => {
          this.userPortfolio = resp.content;
          this.loading = false;
        })
        .catch((error) => {
          this.edit = false;
          this.loading = false;
        });
    },
    async getPartnerB2bInfo() {
      await this.apiService
        .getRequest(`partnerb2b/${this.partnerB2bId}`)
        .then((resp) => {
          this.partnerB2bInfo = resp.content;
        })
        .catch((err) => {
          this.$toast.error("an_error_ocurred");
        });
    },
    async handleSavedHighlightedCompanies() {
      await this.getUserInfo();
    },
    async getUserInfo() {
      this.loading = true;
      await this.apiService
        .getRequest("user/".concat(this.user_id))
        .then(async (resp) => {
          this.user = JSON.parse(resp);
          this.user.maxInvestment = 50000;
          this.user.minRevenue = 0;
          this.user.maxRevenue = 1000000;
          if (this.user.InvestIntended == 1) {
            this.user.maxInvestment = 100000;
          } else if (this.user.InvestIntended == 2) {
            this.user.maxInvestment = 500000;
          } else if (this.user.InvestIntended == 3) {
            this.user.maxInvestment = 1000000;
          } else if (this.user.InvestIntended > 3) {
            this.user.maxInvestment = null;
          }

          if (this.user.CompanyRevenue == 1) {
            this.user.minRevenue = 1000000;
            this.user.maxRevenue = 5000000;
          } else if (this.user.CompanyRevenue == 2) {
            this.user.minRevenue = 5000000;
            this.user.maxRevenue = null;
          }

          this.userType = this.user.Type;
          this.partnerB2bId = this.user.PartnerB2bId;
          if (this.partnerB2bId) {
            await this.getPartnerB2bInfo();
          }
          this.edit = true;
          this.loading = false;
        })
        .catch((error) => {
          this.edit = false;
          this.loading = false;
        });
    },
    async user_saved(user) {
      await this.getUserInfo();
    },
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
    async update_files(files) {
      await this.getUserInfo();
    },
    page_title: function () {
      if (this.edit && !this.loading) {
        return this.user.Name + " " + this.user.LastName;
      } else if (!this.edit) {
        return this.$t("add_user");
      } else {
        return "";
      }
    },
  },
};
</script>
