<template>
  <div>
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_file_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-simple-table v-if="!companiesLoading">
      <thead>
        <tr>
          <th>{{ $t("name") }}</th>
          <th class="text-center">{{ $t("document_type") }}</th>
          <th class="text-center">
            {{ $tc("company", 1) }}
          </th>
          <th class="text-center">
            {{ $t("download") }}
          </th>
          <th class="text-center">
            {{ $t("remove") }}
          </th>
          <!-- <th class="text-center">{{ $t("options") }}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, i) in files" :key="i">
          <td>{{ file.FileName }}</td>
          <td class="text-center">
            {{
              file.UserFileType ? $t(documentTypeText(file.UserFileType)) : "-"
            }}
          </td>
          <td class="text-center">{{ get_file_company(file.CompanyId) }}</td>
          <td class="text-center">
            <v-btn
              icon
              :download="file.FileName"
              class="px-2 ml-1 btnDownloadFile"
              :id="file.FileName"
              :name="file.FileName"
              color="primary"
              @click="download_contract(file, file.FileName)"
              min-width="0"
              small
              :loading="fileDownloading == file.Path"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              :disabled="!file.CanBeDeleted"
              class="px-2 ml-1"
              @click="openDeleteDialog(file)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <!-- <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_file_dialog(file)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn> -->
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <v-card class="dxa_modal">
        <v-card-title>
          <h4 class="dxa_modal_title h4">{{ $t("new_file") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-form @submit.stop.prevent="check_form()" ref="form" class="mt-5">
            <v-select
              :items="index_options"
              :label="$t('index')"
              v-model="selected_index"
              :rules="[required]"
              outlined
            ></v-select>
            <v-select
              :items="companies"
              item-text="Name"
              item-value="CompanyId"
              :label="$tc('company', 1)"
              v-model="company_id"
              outlined
            >
            </v-select>

            <v-select
              :items="documentOptions"
              :item-text="translateFilesOptions"
              item-value="value"
              :label="$t('document')"
              v-model="selectedDocumentType"
              :rules="[required]"
              outlined
            ></v-select>

            <v-file-input
              v-if="!edit"
              prefix
              label="File input"
              @change="handleSelectFile"
              :rules="[required]"
            />

            <v-card-actions class="pl-0 dxa_modal_actions">
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                :loading="loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="red"
                min-width="100"
                @click="dialog = false"
                >{{ $t("cancel") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.FileName"
        @close="delete_dialog = false"
        @delete="deleteFile"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { userFileEnums } from "@/shared/enums/UserFileEnums";
import moment from "moment";
export default {
  name: "ManageUserFiles",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    dialog: false,
    companiesLoading: true,
    delete_dialog: false,
    delete_obj: null,
    error: null,
    edit: false,
    files: [],
    selected_file: {},
    company_id: null,
    selected_index: null,
    fileDownloading: false,
    companies: [],
    CommitmentTerms: [],
    userFileEnums,
    selectedDocumentType: null,
    documentOptions: [
      {
        key: "managed_portfolio_contract",
        value: userFileEnums.MANAGED_PORTFOLIO_CONTRACT,
      },
      {
        key: "investment_proxy",
        value: userFileEnums.INVESTMENT_PROXY,
      },
      {
        key: "commitment_term",
        value: userFileEnums.COMMITMENT_TERM,
      },
      {
        key: "contract_renew",
        value: userFileEnums.CONTRACT_RENEW,
      },
      {
        key: "alpha_contract_amendment",
        value: userFileEnums.ALPHA_CONTRACT_AMENDMENT,
      },
      {
        key: "terms_use",
        value: userFileEnums.USE_TERMS,
      },
      {
        key: "newsletter_subscription",
        value: userFileEnums.NEWS_LETTER_SUBSCRIPTION,
      },
      {
        key: "adhesion_term_single",
        value: userFileEnums.ADHESION_TERM,
      },
      {
        key: "others",
        value: userFileEnums.OTHERS,
      },
      {
        key: "transfer_receipt_single",
        value: userFileEnums.TRANSFER_RECEIPT,
      },
    ],
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (i = 1; i <= this.files.length; i++) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(this.files.length + 1);
      }
      return return_list;
    },
  },
  props: {
    user_prop: Object,
  },

  async created() {
    this.refresh_files();
    this.get_companies();
    this.CommitmentTerms = this.user_prop.Interests.filter(
      (x) => x.CommitmentTerm != null && x.CommitmentTerm != ""
    );
  },
  watch: {
    user_prop() {
      this.refresh_files();
    },
  },
  methods: {
    handleSelectFile(e) {
      this.selected_file = e;
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    get_date_string(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    get_file_company(companyId) {
      if (companyId == null || companyId == 0) {
        return "-";
      } else {
        return this.companies.filter((x) => x.CompanyId == companyId)[0].Name;
      }
    },
    get_companies() {
      this.companiesLoading = true;
      this.apiService
        .getRequest(`company/list`)
        .then((resp) => {
          this.companies = JSON.parse(resp.message);
          this.companiesLoading = false;
        })
        .catch((error) => {});
    },
    documentTypeText(documentType) {
      const literal = {
        [userFileEnums.MANAGED_PORTFOLIO_CONTRACT]:
          "managed_portfolio_contract",
        [userFileEnums.INVESTMENT_PROXY]: "investment_proxy",
        [userFileEnums.COMMITMENT_TERM]: "commitment_term",
        [userFileEnums.CONTRACT_RENEW]: "contract_renew",
        [userFileEnums.ALPHA_CONTRACT_AMENDMENT]: "alpha_contract_amendment",
        [userFileEnums.USE_TERMS]: "terms_use",
        [userFileEnums.NEWS_LETTER_SUBSCRIPTION]: "newsletter_subscription",
        [userFileEnums.ADHESION_TERM]: "adhesion_term_single",
        [userFileEnums.OTHERS]: "others",
        [userFileEnums.TRANSFER_RECEIPT]: "transfer_receipt_single",
      };

      return literal[documentType];
    },
    async download_contract(doc, name) {
      if (name.split(".").length == 1) {
        name += ".pdf";
      }

      this.fileDownloading = doc.Path;
      await this.apiService
        .getRequest(`investor/file?id=${doc.Id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {});
      this.fileDownloading = null;
    },
    refresh_files() {
      if (this.user_prop != null) {
        this.files = this.user_prop.Files;
      }
    },
    openDeleteDialog(file) {
      this.delete_obj = file;
      this.delete_dialog = true;
    },
    async deleteFile(file) {
      this.loading = true;
      this.error = null;

      await this.apiService
        .deleteRequest(`investor/file/${file.Id}`)
        .then((result) => {
          this.refresh_files();
          this.delete_dialog = false;
          this.loading = false;
          this.$emit("reset", null);
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
        });
    },
    edit_file_dialog: function (file) {
      this.edit = true;
      this.selected_file = file;
      this.selected_index = file.Index;
      this.company_id = file.CompanyId;
      this.dialog = true;
    },
    add_file_dialog: function () {
      this.edit = false;
      this.selected_file = {};
      this.selected_index = this.files.length + 1;
      this.company_id = null;
      this.selectedDocumentType = null;
      this.dialog = true;
    },
    translateFilesOptions(item) {
      return this.$t(item.key);
    },
    check_form: async function () {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      }

      if (!result) {
        this.loading = false;
      }
    },
    submit: async function () {
      this.error = null;
      this.loading = true;

      if (this.edit) {
        // para usar essa request tem q alinhar com o novo payload
        this.apiService
          .putRequest("investor/file", {
            File: this.selected_file,
            Index: this.selected_index,
            CompanyId: this.company_id,
          })
          .then((result) => {
            var objs = JSON.parse(result);
            this.$emit("reset", objs);
            this.refresh_files();
            this.loading = false;
            this.dialog = false;
          })
          .catch((error) => {
            this.error = error.body.message;
            this.loading = false;
          });
      } else {
        let string64;
        var reader = new FileReader();
        reader.readAsDataURL(this.selected_file);
        reader.onload = async () => {
          string64 = reader.result;

          let data = {
            userId: this.user_prop.Id,
            fileName: this.selected_file.name,
            id: 0,
            userFileType: this.selectedDocumentType,
            index: this.selected_index,
            companyId: this.company_id,
            fileString: string64,
          };

          await this.apiService
            .postRequest("investor/file", data)
            .then((result) => {
              var objs = result.content;
              this.$emit("reset", objs);
              this.refresh_files();
              this.loading = false;
              this.dialog = false;
            })
            .catch((error) => {
              this.error = error.body.message;
              this.loading = false;
            });
        };
      }
    },
  },
};
</script>
